@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "LEMON_MILK";
  src: url("../public/fonts/LEMONMILK.otf");
}

@font-face {
  font-family: "MONOSPACE_TYPEWRITER";
  src: url("../public/fonts/MONOSPACETYPEWRITER.ttf");
}

body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 0.2rem;
  border-radius: 0.6rem;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.6rem;
  background-color: #21222c;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #363536;
}

.mouse::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 6px;
  background: #504ff9;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  opacity: 1;
  animation: mouse 1.5s infinite;
}

@keyframes mouse {
  from {
    opacity: 1;
    top: 6px;
  }
  to {
    opacity: 0;
    top: 23px;
  }
}
